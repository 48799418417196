<template>
  <div
    ref="accordion"
    class="accordion-block"
    data-testid="Accordion"
  >
    <div
      v-for="(panel, index) in panels"
      :id="panel.id"
      :key="panel.id"
      class="accordion-panel"
    >
      <AccordionPanel
        :item="panel"
        :theme="config.accordion.theme"
        :globals="globals"
        :col-index="colIndex"
        :icon="config.accordion.icon_style"
        @toggle-panel="
          config.accordion.allow_multiple_open_panels ? toggleSinglePanel(panel.id) : toggleCurrentlyOpenPanel(panel.id)
        "
        @next-panel="focusNextPanel(index)"
        @previous-panel="focusPreviousPanel(index)"
        @first-panel="focusFirstPanel"
        @last-panel="focusLastPanel"
      />
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import AccordionPanel from './AccordionPanel';

export default {
  name: 'AccordionBlock',
  components: {
    AccordionPanel,
  },
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    rowType: {
      type: String,
      default: '',
    },
    colIndex: {
      type: Number,
      required: true,
    },
    globals: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      panels: [],
      icon: '',
    };
  },
  created() {
    this.icon = this.config.accordion.icon_style;
    this.panels = cloneDeep(this.config.accordion.items);
    this.panels = this.panels.map((panel) => ({ ...panel, expanded: false }));
  },
  methods: {
    toggleCurrentlyOpenPanel(id) {
      this.panels = this.panels.map((panel) =>
        panel.id === id ? { ...panel, expanded: !panel.expanded } : { ...panel, expanded: false },
      );
    },
    toggleSinglePanel(id) {
      this.panels = this.panels.map((panel) =>
        panel.id === id ? { ...panel, expanded: !panel.expanded } : { ...panel },
      );
    },
    focusNextPanel(index) {
      if (index + 1 === this.$refs?.accordion?.children?.length) return;
      const focusedIndex = index + 1;
      const nextPanelHeader = this.$refs?.accordion?.children[focusedIndex]?.children[0]?.querySelector('button');
      if (!nextPanelHeader) return;
      nextPanelHeader.focus();
    },
    focusPreviousPanel(index) {
      if (index === 0) return;
      const focusedIndex = index - 1;
      const previousPanelHeader = this.$refs?.accordion?.children[focusedIndex]?.children[0]?.querySelector('button');
      if (!previousPanelHeader) {
        return;
      }
      previousPanelHeader.focus();
    },
    focusFirstPanel() {
      const firstPanelHeader = this.$refs?.accordion?.children[0]?.children[0]?.querySelector('button');
      if (!firstPanelHeader) {
        return;
      }
      firstPanelHeader.focus();
    },
    focusLastPanel() {
      const focusedIndex = this.$refs?.accordion?.children.length - 1;
      const lastPanelHeader = this.$refs?.accordion?.children[focusedIndex]?.children[0]?.querySelector('button');
      if (!lastPanelHeader) {
        return;
      }
      lastPanelHeader.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
div.accordion-block {
  top: 40px;
  padding-bottom: 1px;
  margin: 0 auto;
}
</style>
