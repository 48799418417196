<template>
  <PasswordProtected
    v-if="needsPasswordValidation"
    :incorrect-password="incorrectPassword"
    @submit-password="submitPassword"
  />
  <div
    v-else
    id="page"
    :class="{ 'page-renderer-package-enabled': isPageRendererPackageEnabled }"
  >
    <a-builder
      v-if="isPageRendererPackageEnabled && isPageReady"
      :model-value="getBuilderConfig"
      is-render-mode
    />
    <template v-else>
      <RowRenderer
        v-for="row in page.content"
        :key="row.id"
        :row="row"
      />
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import PasswordProtected from './PasswordProtected.vue';
import RowRenderer from './cms/RowRenderer.vue';

export default {
  name: 'PageContent',
  components: {
    PasswordProtected,
    RowRenderer,
  },
  props: {
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: false,
      incorrectPassword: false,
      needsPasswordValidation: false,
      formattedInPageSharedContent: {},
      isPageReady: false,
    };
  },
  computed: {
    ...mapState(['globals', 'featureFlags', 'page', 'inPageSharedContent']),
    ...mapGetters(['getFullSiteMap', 'getSecondaryOrgId']),
    ...mapGetters('translation', ['getLocale']),
    isPageRendererPackageEnabled() {
      return !!this.featureFlags.cms_page_renderer_package_enabled && this.page?.flags?.new_renderer_enabled;
    },
    getBuilderConfig() {
      return {
        ...this.page.content,
        customProperties: {
          global: {
            backgroundColorAllowed: true,
            backgroundImageAllowed: true,
          },
          CONTENT_NODE_NAVIGATION: {
            siteMenu: this.getFullSiteMap || null,
          },
        },
        theme: {
          mainColor: this.globals.info.page_builder_theme_color?.hex,
          headingColor: this.globals.info.cms_heading_color?.hex,
          linkColor: this.globals.info.links_color?.hex,
          primaryColor: this.globals.info.primary_color?.hex,
          secondaryColor: this.globals.info.secondary_color?.hex,
        },
        api: {
          forms: `${useRuntimeConfig().public.FORMS_SERVICE_API_URL}/api/v1/`,
          secondaryOrgId: this.getSecondaryOrgId,
        },
        sharedContent: {
          content: this.formattedInPageSharedContent,
        },
        locale: this.getLocale,
      };
    },
  },
  created() {
    this.needsPasswordValidation = !!this.page?.password_protected && !this.isPreview;
  },
  async mounted() {
    if (this.isPageRendererPackageEnabled) {
      this.formattedInPageSharedContent = this.formatPageSharedContent();
    }
    if (this.$store.state.pageContentNeedsRefresh) {
      this.$store.commit('setPageContentNeedsRefresh', false);
      window.location.reload();
    }
    this.isPageReady = true;
  },
  methods: {
    formatPageSharedContent() {
      const activeSharedContent = this.inPageSharedContent.filter((sharedContent) => sharedContent.status === 'active');
      return activeSharedContent.reduce((acc, { id, name, content }) => {
        acc[id] = { name, content, icon: '16:paper-plane' };
        return acc;
      }, {});
    },
    async submitPassword(password) {
      const pageContentUrl = `api/v4/o/${this.getSecondaryOrgId}/cms/pages/${this.page.slug}/unlock?locale=${this.page.langCode}`;

      try {
        const { data } = await this.$axios.post(pageContentUrl, { password });
        this.$store.dispatch('setPageData', data);
        this.grantAccess();
      } catch {
        this.incorrectPassword = true;
      }
    },
    grantAccess() {
      this.incorrectPassword = false;
      this.needsPasswordValidation = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './global/scss/mixins/breakpoints';

#page {
  min-height: 500px;
  display: block;
  margin-bottom: 15px;
  &.page-renderer-package-enabled {
    margin-bottom: 0;
  }
}
/** Extra Small to Large Phones **/
@include screen('xs') {
  .row {
    display: block;
  }
  .column {
    display: inline-block;
    width: 100%;
  }
  .ts-left-sidebar {
    &.column:nth-child(1) {
      width: 100%;
    }
    &.column:nth-child(2) {
      width: 100%;
      margin-left: 0;
    }
  }
  .ts-right-sidebar {
    &.column:nth-child(1) {
      width: 100%;
    }
    &.column:nth-child(2) {
      width: 100%;
      margin-left: 0;
    }
  }
  .ts-two-column {
    &.column:nth-child(2) {
      margin-left: 0;
    }
  }
  .ts-three-column,
  .ts-four-column {
    &.column {
      margin-left: 0;
    }
  }
}
</style>
