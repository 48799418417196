<template>
  <div
    class="table-v2-block"
    :style="themeStyles"
    :class="hasAlternatingColors && 'alternating-colors'"
  >
    <div
      ref="scrollShadowWrapper"
      :class="{ 'show-left-shadow': showLeftScrollShadow, 'show-right-shadow': showRightScrollShadow }"
      class="scroll-shadow-wrapper"
      @scroll.passive="updateScrollShadows"
    >
      <div v-html="getContent" />
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import tinycolor from 'tinycolor2';

export default {
  name: 'TableV2Block',
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    globals: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      debouncedUpdateStyles: () => {},
      showLeftScrollShadow: false,
      showRightScrollShadow: false,
    };
  },
  computed: {
    headingColor() {
      return get(this.globals, 'info.cms_heading_color.hex', '#333');
    },
    pageBuilderThemeColor() {
      return get(this.globals, 'info.page_builder_theme_color.hex', this.headingColor);
    },
    getTheme() {
      return get(this.config, 'table.theme', 'default');
    },
    getContent() {
      return get(this.config, 'table.content', '');
    },
    hasAlternatingColors() {
      return get(this.config, 'table.alternatingColors', 'false');
    },
    linkColor() {
      return get(this.globals, 'info.links_color.hex', '#0000ee');
    },
    themeTypes() {
      return [
        {
          name: 'default',
          backgroundColor: '#F3F3F3',
          textColor: '#333',
          rowColor: '#F3F3F3',
        },
        {
          name: 'light',
          backgroundColor: `${this.pageBuilderThemeColor}0D`,
          textColor: this.pageBuilderThemeColor,
          rowColor: '#FBFBFB',
        },
        {
          name: 'dark',
          backgroundColor: this.pageBuilderThemeColor,
          textColor: '#fff',
          rowColor: `${this.pageBuilderThemeColor}0D`,
        },
      ];
    },
    themeStyles() {
      const theme = this.themeTypes.find((t) => t.name === this.getTheme) || 'default';

      return {
        '--header-bg-color': this.convertHexWithAlphaToSixDigitHex(theme.backgroundColor),
        '--header-text-color': theme.textColor,
        '--header-link-color': this.getTheme === 'dark' ? theme.textColor : this.linkColor,
        '--alternating-row-color': this.convertHexWithAlphaToSixDigitHex(theme.rowColor),
      };
    },
  },
  mounted() {
    this.debouncedUpdateStyles = debounce(this.updateStyles, 100);
    window.addEventListener('resize', this.debouncedUpdateStyles);

    this.updateStyles();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.debouncedUpdateStyles);
  },
  methods: {
    convertHexWithAlphaToSixDigitHex(colorToConvert) {
      const color = tinycolor(colorToConvert);
      const alpha = color.getAlpha();
      const mixedColor = tinycolor.mix('white', color.setAlpha(1), alpha * 100);
      return mixedColor.toHexString();
    },
    updateStyles() {
      if (!this.config || !this.$refs.scrollShadowWrapper) return;

      this.updateScrollShadows({ target: this.$refs.scrollShadowWrapper });
    },
    updateScrollShadows(evt) {
      if (evt.target === null || evt.target === undefined) return;

      const { scrollWidth, scrollLeft, clientWidth } = evt.target;
      this.showLeftScrollShadow = scrollLeft > 0;
      this.showRightScrollShadow = scrollWidth > clientWidth + scrollLeft + 10;
    },
  },
};
</script>
<style lang="scss" scoped>
$color-grey: #e1e1e1;
.table-v2-block {
  position: relative;
  .scroll-shadow-wrapper {
    $transparent: rgba(255, 255, 255, 0);
    $white: rgba(255, 255, 255, 1);
    overflow-x: auto;
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 30px;
      height: 100%;
      z-index: 1;
      pointer-events: none;
    }
    &:after {
      top: 0;
      right: 0;
    }
    &.show-right-shadow::after {
      background: linear-gradient(to right, $transparent, $white);
    }
    &.show-left-shadow::before {
      background: linear-gradient(to left, $transparent, $white);
    }
  }
}
:deep(table) {
  min-width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  margin: 0;
  td,
  th {
    min-width: 100px;
    border: 1px solid $color-grey;
    padding: 3px 5px;
    vertical-align: top;
    box-sizing: border-box;
    position: relative;
    > * {
      margin-top: 0;
      margin-bottom: 19px;
    }
    :last-child {
      margin-bottom: 0;
    }
  }
  th {
    font-weight: bold;
    text-align: left;
    background-color: var(--header-bg-color);
    color: var(--header-text-color);
    a {
      color: var(--header-link-color);
    }
  }
  tr {
    background-color: #fff;
  }
  .alternating-colors & tr:nth-child(2n + 1) td {
    background-color: var(--alternating-row-color);
  }
  td > ol,
  td > ul,
  th > ol,
  th > ul {
    list-style: inside;
    padding-left: 0;
  }
  ul li {
    list-style-type: disc;
  }
  ol li {
    list-style-type: decimal;
  }
  li {
    p {
      display: inline;
      margin-bottom: 0;
      &:last-child {
        height: 0;
      }
    }
    ul li {
      list-style-type: circle;
    }
  }
  code {
    font-family: SFMono-Medium, 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', 'Ubuntu Mono', Menlo, Consolas, Courier,
      monospace;
    font-weight: normal;
    font-size: 16px;
    background-color: rgba(9, 30, 66, 0.08);
    color: #172b4d;
    border-style: none;
    border-radius: 3px;
    display: inline;
    padding: 2px 0.5ch;
    overflow-wrap: break-word;
    overflow: auto;
    -webkit-box-decoration-break: clone;
  }
}
</style>
